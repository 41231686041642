import React, { ReactNode } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useIntl, FormattedHTMLMessage, IntlFormatters } from 'gatsby-plugin-react-intl';

import { Container, SectionHeading, ProductLink } from './products.styles';

export interface ProductsProps {
  children?: ReactNode;
}

const getProductList = (formatMessage: IntlFormatters['formatMessage']) => {
  return [
    {
      type: formatMessage({ id: 'products.product_1.type' }),
      name: formatMessage({ id: 'products.product_1.name' }),
      description: formatMessage({ id: 'products.product_1.description' }),
      image: (
        <StaticImage
          className="lg:h-80 md:h-60 w-full object-cover object-center"
          src="../../../images/srednicowka-przyrzad-do-pomiaru-srednic-otworow-wewnetrznych-wytaczarek.webp"
          alt={formatMessage({ id: 'products.product_1.image_alt' })}
        />
      ),
      link: formatMessage({ id: 'products.product_1.link' }),
    },
    {
      type: formatMessage({ id: 'products.product_2.type' }),
      name: formatMessage({ id: 'products.product_2.name' }),
      description: formatMessage({ id: 'products.product_2.description' }),
      image: (
        <StaticImage
          className="lg:h-80 md:h-60 w-full object-cover object-center"
          src="../../../images/przyrzad-do-centrowania-wrzeciona-wytaczarki-stacjonarnej-lub-mobilnej.webp"
          alt={formatMessage({ id: 'products.product_2.image_alt' })}
        />
      ),
      link: formatMessage({ id: 'products.product_2.link' }),
    },
    {
      type: formatMessage({ id: 'products.product_3.type' }),
      name: formatMessage({ id: 'products.product_3.name' }),
      description: formatMessage({ id: 'products.product_3.description' }),
      image: (
        <StaticImage
          className="lg:h-80 md:h-60 w-full object-cover object-top"
          src="../../../images/mikrometryczny-przyrzad-do-wysuwania-noza.webp"
          alt={formatMessage({ id: 'products.product_3.image_alt' })}
        />
      ),
      link: formatMessage({ id: 'products.product_3.link' }),
    },
  ];
};

export const Products = () => {
  const { formatMessage } = useIntl();
  const productList = getProductList(formatMessage);

  return (
    <Container
      id={formatMessage({ id: 'products.section_id' })}
      className="text-gray-600 body-font bg-white w-full pt-24"
    >
      <FormattedHTMLMessage tagName={SectionHeading} id="products.heading" />
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap -m-4">
          {productList.map(({ type, name, description, image, link }) => (
            <div key={name} className="p-4 md:w-1/3">
              <div className="h-full bg-blue-500 rounded-lg overflow-hidden">
                {image}
                <div className="p-6 text-white">
                  <h2 className="tracking-widest text-xs title-font font-medium text-blue-200 mb-1 uppercase">
                    {type}
                  </h2>
                  <h1 className="title-font text-lg font-medium mb-3">{name}</h1>
                  <p className="leading-relaxed text-left text-sm h-full">{description}</p>
                  <ProductLink to={link}>{formatMessage({ id: 'products.cta_label' })}</ProductLink>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};
