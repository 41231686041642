import React from 'react';
import { PageProps } from 'gatsby';
import { Helmet } from 'react-helmet-async';
import { StaticImage } from 'gatsby-plugin-image';
import { useIntl, FormattedHTMLMessage } from 'gatsby-plugin-react-intl';

import { Article } from '../theme/typography';
import { Navigation } from '../shared/components/navigation';
import { Hero } from '../shared/components/hero';
import { ContentSection, SECTION_ALIGN } from '../shared/components/contentSection';
import { Footer } from '../shared/components/footer';
import { Products } from '../shared/components/products';
import { ContactFormSection } from '../shared/components/contactFormSection';

interface IndexProps {
  site: {
    buildTime: string;
  };
}

const Index: React.FC<PageProps<IndexProps>> = () => {
  const intl = useIntl();

  return (
    <Article>
      <Navigation />
      <Hero />
      <Helmet
        htmlAttributes={{
          lang: intl.locale,
        }}
        title={intl.formatMessage({ id: 'title' })}
        meta={[
          {
            name: `description`,
            content: intl.formatMessage({ id: 'description' }),
          },
        ]}
      />
      <ContentSection
        id={intl.formatMessage({ id: 'homepage.section_1.section_id' })}
        dark
        align={SECTION_ALIGN.RIGHT}
        image={
          <StaticImage
            className="object-cover object-center rounded"
            src="../images/koparka.jpg"
            alt={intl.formatMessage({ id: 'homepage.section_1.section_img_alt' })}
          />
        }
      >
        <h2 className="mb-4 text-3xl font-extrabold md:text-4xl">
          {intl.formatMessage({ id: 'homepage.section_1.title1' })}
          <br />
          <span className="text-gray-200">{intl.formatMessage({ id: 'homepage.section_1.title2' })}</span>
        </h2>
        <FormattedHTMLMessage
          tagName="p"
          // @ts-ignore
          className="mb-8 leading-relaxed text-gray-100"
          id="homepage.section_1.content"
        />
      </ContentSection>
      <ContentSection
        dark
        middle
        image={
          <StaticImage
            className="object-cover object-center rounded"
            src="../images/dokladnosc-wytaczania.jpg"
            alt={intl.formatMessage({ id: 'homepage.section_2.section_img_alt' })}
          />
        }
      >
        <h2 className="mb-4 text-3xl font-extrabold md:text-4xl">
          {intl.formatMessage({ id: 'homepage.section_2.title' })}
        </h2>
        <p className="mb-8 leading-relaxed text-gray-100">
          {intl.formatMessage({ id: 'homepage.section_2.content1' })}
        </p>
        <FormattedHTMLMessage
          tagName="p"
          // @ts-ignore
          className="mb-8"
          id="homepage.section_2.content2"
        />
      </ContentSection>
      <Products />
      <ContactFormSection />
      <Footer />
    </Article>
  );
};

export default Index;
