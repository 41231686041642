import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import { Link } from 'gatsby-plugin-react-intl';

export const Container = tw.section`
text-gray-600 body-font bg-white w-full pt-24
`;

export const SectionHeading = tw.h2`
  text-4xl
  sm:text-5xl
  font-black
  tracking-wide
  text-center
  w-full
  text-gray-900
`;

export const ProductLink = tw(Link)`
  md:w-full
  flex
  items-center
  justify-center
  px-8
  py-3
  mt-6
  border
  border-transparent
  text-base
  font-medium
  rounded-md
  text-blue-500
  bg-white
  transition
  duration-300
  hover:bg-gray-100
  md:py-4
  md:text-lg
  md:px-10
`;
