import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { Container } from './contentSection.styles';

export enum SECTION_ALIGN {
  LEFT,
  RIGHT,
}

export interface ContentSectionProps {
  children?: ReactNode;
  align?: SECTION_ALIGN;
  dark?: boolean;
  image: JSX.Element;
  middle?: boolean;
  id?: string;
}

export const ContentSection = ({
  children,
  align = SECTION_ALIGN.LEFT,
  dark = false,
  image,
  middle,
  id,
}: ContentSectionProps): JSX.Element => {
  const isLeftAlign = align === SECTION_ALIGN.LEFT;

  const columnsContainerClasses = classNames('container mx-auto flex px-5 py-24 flex-col items-start', {
    'md:flex-row': isLeftAlign,
    'md:flex-row-reverse': !isLeftAlign,
    'pt-0': middle,
  });

  const imageWrapperClasses = classNames('lg:max-w-lg w-full md:w-1/2 w-5/6');

  const contentWrapperClasses = classNames(
    'lg:flex-grow md:w-1/2 flex flex-col md:items-start items-center text-left',
    {
      'lg:pr-24 md:pr-16': isLeftAlign,
      'lg:pl-24 md:pl-16': !isLeftAlign,
      'text-white': dark,
    }
  );

  return (
    <Container id={id}>
      <div className={columnsContainerClasses}>
        <div className={contentWrapperClasses}>{children}</div>
        <div className={imageWrapperClasses}>{image}</div>
      </div>
    </Container>
  );
};
